import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {NgClass} from "@angular/common";

import * as THREE from 'three';

declare var VANTA: any;
// declare var VANTA: {
//     TOPOLOGY: (arg0: {
//         el: any; THREE: typeof THREE; // Pass THREE.js as a parameter
//         mouseControls: boolean; touchControls: boolean; gyroControls: boolean; minHeight: number; minWidth: number; scale: number; scaleMobile: number; color: number; backgroundColor: number;
//     }) => any;
// };
// import TOPOLOGY from 'vanta/dist/vanta.topology.min';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgClass, RouterLinkActive, RouterLink],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  title = 'keldysh-landing-page';

  @ViewChild('bodyBackground') vantaElement!: ElementRef;
  vantaEffect: any;

  navbarCollapsed = true;
  pageLoaded = false;

  protected readonly window = window;

  ngAfterViewInit(): void {
    this.vantaEffect =
      VANTA.TOPOLOGY({
        el: this.vantaElement.nativeElement,
        // el: "#bodyBackground",
        THREE: THREE, // Pass THREE.js as a parameter
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 10.00,
        scaleMobile: 5.00,
        color: 0x999999,
        backgroundColor: 0x0,
        // resize: true,  // Ensures Vanta handles resize
        // onResize: this.handleResize.bind(this) // Custom resize handler
      });
    // todo: check this
    this.vantaEffect.renderer.properties.frameRate =10;

    // todo: check this
    // Pause the Vanta effect after 10 seconds (10000 milliseconds)
    setTimeout(() => {
      this.pauseVantaEffect();
    }, 10000);
  }

  // handleResize(): void {
  //   // Custom behavior during resize
  //   if (this.vantaEffect) {
  //     this.vantaEffect.resize();
  //     // Ensure the pause is reapplied after resize
  //     this.pauseVantaEffect();
  //   }
  // }

  pauseVantaEffect(): void {
    if (this.vantaEffect) {
      // Stop the rendering loop
      this.vantaEffect.renderer.setAnimationLoop(null);
      this.vantaEffect.renderer.properties.frameRate =10;

      // Optionally, detach mouse/touch controls to reduce interactions
      this.vantaEffect.mouseControls = false;
      this.vantaEffect.touchControls = false;
    }
  }

  resumeVantaEffect(): void {
    if (this.vantaEffect) {
      // Reattach controls if needed
      this.vantaEffect.mouseControls = true;
      this.vantaEffect.touchControls = true;

      // Restart the animation loop
      this.vantaEffect.renderer.setAnimationLoop(() => {
        this.vantaEffect.renderer.render(this.vantaEffect.scene, this.vantaEffect.camera);
      });
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 0);
  }

  toggleCollapsed(): void {
    this.navbarCollapsed = !this.navbarCollapsed;
  }

  ngOnDestroy(): void {
    this.vantaEffect.destroy();
  }
}
