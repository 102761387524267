import {Component, HostBinding, OnInit} from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-hero',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './hero.component.html',
  styleUrl: './hero.component.scss'
})
export class HeroComponent implements OnInit {

  @HostBinding('class') class = 'container flex-grow-1 d-flex justify-content-center align-items-center';

  pageLoaded = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 0);
  }
}
