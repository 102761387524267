<section id="hero" [ngClass]="{'page-loaded': pageLoaded}">
  <!--      <div class="container d-flex flex-column ">-->
  <!--      <div class="row mt-5">-->
  <!--        <div class="col-lg-6">-->
  <!--          &lt;!&ndash; p class="mb-0"><span>// </span>The Digitalization Platform - Efficient, Reliable, at Scale</p&ndash;&gt;-->
  <!--          <h1 class="text-body my-0">The Digital Foundation</h1>-->
  <!--        </div>-->
  <!--        <div class="col-lg-6 text-lg-end">-->
  <!--          <p class="mb-0"><span>// </span>More coming soon. Stay tuned!</p>-->
  <!--        </div>-->
  <!--      </div>-->
  <div class="row py-5 my-auto ">
    <div class="col">
      <svg width="1520" height="404" viewBox="0 0 1520 404" fill="#fff"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1316.15 317.137H1352.39V194.825C1352.39 149.525 1381.38 120.079 1422.6 120.079C1458.84 120.079 1483.76 140.465 1483.76 185.765V317.137H1520V183.5C1520 122.344 1483.76 86.1037 1429.4 86.1037C1402.22 86.1037 1382.74 96.0698 1370.51 105.583C1363.26 111.019 1357.37 117.361 1352.84 124.609H1352.39V0H1316.15V317.137Z"/>
        <path
          d="M1078.29 246.921C1082.36 260.964 1088.71 273.649 1097.31 284.521C1112.26 303.094 1138.54 321.667 1182.48 321.667C1243.63 321.667 1275.34 289.957 1275.34 253.716C1275.34 212.04 1238.2 194.825 1200.6 187.124C1163 179.423 1125.85 171.722 1125.85 149.525C1125.85 136.387 1141.25 120.079 1175.68 120.079C1199.24 120.079 1214.19 128.686 1222.8 137.293C1227.78 142.276 1231.86 148.166 1234.57 154.055H1273.08C1269.45 141.37 1263.57 130.045 1255.86 120.079C1241.37 103.318 1217.81 86.1036 1175.68 86.1036C1119.06 86.1036 1089.61 115.549 1089.61 149.525C1089.61 191.654 1126.76 208.416 1164.36 216.117C1201.96 223.818 1239.1 231.519 1239.1 253.716C1239.1 271.836 1223.7 287.692 1182.48 287.692C1155.75 287.692 1140.35 277.726 1129.93 267.306C1124.04 261.417 1119.96 254.622 1116.79 246.921H1078.29V246.921Z"/>
        <path
          d="M1032.99 90.6014L974.062 283.162H940.086L890.256 90.6014H851.75L912.906 317.137H965.002L937.821 403.241H976.327L1071.49 90.6014H1032.99Z"/>
        <path
          d="M629.744 203.885C629.744 154.508 663.267 120.079 706.755 120.079C750.244 120.079 783.767 154.508 783.767 203.885C783.767 253.263 750.244 287.692 706.755 287.692C663.267 287.692 629.744 253.263 629.744 203.885ZM593.504 203.885C593.504 271.836 642.881 321.667 702.225 321.667C745.714 321.667 771.082 299.47 779.237 287.239L783.314 280.897H783.767V317.137H820.007V0H783.767V126.874H783.314L779.237 120.079C771.082 108.754 745.714 86.1037 702.225 86.1037C642.881 86.1037 593.504 135.934 593.504 203.918"/>
        <path d="M482.517 0V317.137H570.853V283.162H518.758V0H482.517Z"/>
        <path
          d="M247.827 183.5C253.716 149.525 282.709 120.079 326.197 120.079C369.686 120.079 398.225 149.525 403.208 183.5H247.795H247.827ZM210.681 203.885C210.681 271.836 260.511 321.667 326.23 321.667C369.265 321.667 396.446 303.094 414.113 284.521C424.079 273.649 431.78 260.964 437.216 246.921H400.976C396.446 254.622 390.557 261.417 383.762 267.306C371.53 277.726 353.41 287.692 326.23 287.692C284.553 287.692 251.937 257.34 246.953 215.211H441.779V201.62C441.779 135.934 391.948 86.0713 326.262 86.0713C260.576 86.0713 210.713 135.902 210.713 203.885"/>
        <path
          d="M0 0V317.137H36.2406V215.211H81.5412L158.585 317.137H203.885L113.284 197.09L197.09 90.6014H154.055L81.5412 181.235H36.2406V0H0Z"/>
      </svg>
    </div>
  </div>
  <!--      </div>-->
</section>
