<div id="about" #about class="py-5" xmlns="http://www.w3.org/1999/html">
  <h1 class="display-5 text-center py-5">About</h1>
  <div class="py-3"></div>
  <section class="section">
    <div class="container py-5 text-area">
      <div class="row align-items-center">
        <div class="col-lg-10 offset-lg-1">
          <!--          <div class="p-5">-->
          <h3 class="py-5"><span class="product-title">keldysh</span> - The Digital Foundation</h3>
          <p>
            At <span class="product-title">keldysh</span>, we build integrative digital products, ranging from highly
            specialized functional libraries to full-blown enterprise business landscapes, both on-premises and in cloud
            environments.
          </p>
          <h4>Vision</h4>
          <p>
            Today's approaches to digitalization are still largely characterized by software tools that optimize isolated
            tasks. These solutions are commonly motivated by formerly manual workflows and therefore retain their respective
            limitations. This results in weakly integrated software landscapes that fail to harness the full power of
            network technology, unnecessarily consuming our most valuable resource—time.
          </p>
          <p>We believe digital transformation should not attempt to digitally recreate human processes but rather
            establish digital-native workflows to achieve the desired goals. In the digital realm, there are no such
            limitations typically causing labor-intensive operational overhead. <br>
            Imagine the impact on modern businesses when not only strategic decisions but also daily operational tasks
            and even sales processes can access information like process efficiency and ROI as measured metrics with
            second-by-second precision, all while no time is spent on overheads like administration, warehouse management,
            or controlling.
          </p>
          <p>
            The best sign of digital transformation going in the wrong direction is an increase of screen time!
          </p>
          <p>
            At <span class="product-title">keldysh</span>, we envision a digital future characterized by symbiotic,
            digitized environments where reliable, autonomous background processes operate seamlessly, minimizing screen
            time to focus on real value.
          </p>
          <h4>Background</h4>
          <p>
            Our core concept originated from experiences in Smart Manufacturing, IIOT, Industry 4.0 and R&D
            - areas defined by globally distributed, diverse, unpredictable, and often mission-critical processes.
            Over the years, our unique approach has evolved into a general-purpose solution. Beyond production
            environments, our technology can represent virtually any setting, from small business sales automation to
            smart city initiatives and global business integrations.
          </p>
          <h4>Paradigm</h4>
          <p>
            Challenges in most digitalization projects often share common traits. At
            <span class="product-title">keldysh</span>, we identify these conceptual similarities—such as race conditions
            from distributed data sources, horizontal scaling limitations, or resilient data processing security—to
            forge general-purpose, application-layer (i.e., commonly declarative) tools, delivering a solid
            digital foundation to seamlessly integrate diverse software and real-world environments.
          </p>
          <h4>In Action</h4>
          <p>
            To help our clients transition from utilizing isolated enterprise solutions to forging symbiotic digitized
            environments, <span class="product-title">keldysh</span> elevates the big data concept to the application
            layer, effectively unifying spatially distributed and structurally diverse business components. Unlike
            common distributed data management, <span class="product-title">keldysh</span> is designed
            for maximum efficiency with any data and workloads, while maintaining maximum data security.
          </p>
          <p>
            Acting as your organization's universal source of truth, keldysh offers precise, accessible information
            when and where you need it. From edge data acquisition to autonomous business administration and advanced
            AI-driven processes, it spans all operational aspects. Experience new levels of end-to-end transparency in
            real time, seamless integration with existing software solutions, and develop new proprietary solutions,
            accessing the full potential behind your entire historical data.
          </p>
          <h4>Join Us</h4>
          <p>
            We welcome you to join us in our mission to build and apply the most comprehensive foundation for real-world
            digitalization. Experience solutions that efficiently achieve your goals, adapt flexibly to your environment,
            preserve your unique characteristics and USPs, perform reliably under any circumstances, and scale indefinitely.
          </p>
          <p class="pt-3">
            <span style="font-weight: bold">Start your transformation today: </span>
            <a href="mailto:contact@keldysh.io">contact&#64;keldysh.io</a>
          </p>
          <!--          </div>-->
        </div>
      </div>
    </div>
  </section>
</div>
